import React,{ useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Image,Button, Select, Form, Input,Modal, message, Switch, Space, InputNumber } from 'antd';
import { cloneDeep } from "lodash"
import { DeleteOutlined } from '@ant-design/icons';


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [cats, setCats] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [form] = Form.useForm()

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.ADMIN_TEMPLATE_LIST, s)
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        http.get(apis.ADMIN_TEMPLATE_CATS).then(r => {
            setCats(r)
            loadData(search).then(r=>{
                setLoading(false)
            })
        })

        

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])

    const doCreate = async () => {
        try {
            let values = await form.validateFields()
            let cpVaules = cloneDeep(values)
            cpVaules.img_url = cpVaules.img_url[0].path
            cpVaules.small_cover = cpVaules.small_cover[0].path
            if(cpVaules.key_words){
                cpVaules.key_words = cpVaules.key_words.join(",")
            }else{
                cpVaules.key_words = ''
            }
            cpVaules.status = cpVaules.status === true ? 2:1
            cpVaules.top_timestamp = cpVaules.top_timestamp === true ? new Date().getTime():0
            setLoading(true)
            try {
                await http.post(apis.PATTERN_CREATE,cpVaules)
                message.success("操作成功")
                setIsModalOpen(false)
                await loadData(search)
            } catch (error) {
                
            }
            setLoading(false)
        } catch (error) {
            
        }
    }


    const doDelete = async (ids) => {
        try {
            await http.post(apis.ADMIN_TEMPLATE_DELETE,{ids: ids})
        } catch (error) {
            
        }
    }

    const handleKws = (item) => {
        if(item.key_words){
            return item.key_words.split(",")
        }else{
            return []
        }
    }

    const handleCats = (item) => {
        if(item.cat_ids){
            return item.cat_ids.split(",")
        }else{
            return []
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: '作品',
            dataIndex: 'cover',
            render: (v) => (
                <Image width={80} height={80} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '关键词',
            dataIndex: 'key_words',
            render: (v,r,dx) => (
                <Select placeholder="输入关键词" style={{minWidth: 150}} value={handleKws(r)} mode="tags" onChange={async i => {
                    let kws = i
                    if(!kws){
                        kws = []
                    }
                    if(kws.length === 0){
                        kws = ''
                    }else{
                        kws = kws.join(",")
                    }
                    let cpData = cloneDeep(response)
                    cpData.list[dx].key_words = kws
                    setResponse(cpData)
                    setLoading(true)
                    try {
                        await http.post(apis.ADMIN_TEMPLATE_UPDATE,{
                            id: r.id,
                            key_words:  kws,
                            cat_ids: r.cat_ids,
                            top_timestamp: r.top_timestamp,
                            status: r.status
                        })
                    } catch (error) {
                        
                    }
                    setLoading(false)

                }} />
            )
        },
        {
            title: '分类',
            dataIndex: 'cat_ids',
            render: (v,r,dx) => (
                <Select options={cats} placeholder="选择分类" style={{minWidth: 150}} value={handleCats(r)} mode="tags" onChange={async i => {
                    let kws = i
                    if(!kws){
                        kws = []
                    }
                    if(kws.length === 0){
                        kws = ''
                    }else{
                        kws = kws.join(",")
                    }
                    let cpData = cloneDeep(response)
                    cpData.list[dx].cat_ids = kws
                    setResponse(cpData)
                    
                    setLoading(true)
                    try {
                        await http.post(apis.ADMIN_TEMPLATE_UPDATE,{
                            id: r.id,
                            key_words:  r.key_words,
                            cat_ids: kws,
                            top_timestamp: r.top_timestamp,
                            status: r.status
                        })
                    } catch (error) {
                        
                    }
                    setLoading(false)

                }} />
            )
        },
        {
            title: '使用数',
            dataIndex: 'used_num',
            render: (v,r,dx) => (
                <InputNumber value={v} min={0} onChange={e => {
                    let cpData = cloneDeep(response)
                    cpData.list[dx].used_num = parseInt(e)
                    setResponse(cpData)
                    setLoading(true)
                    try {
                        http.post(apis.ADMIN_TEMPLATE_UPDATE,{
                            id: r.id,
                            key_words:  r.key_words,
                            cat_ids: r.cat_ids,
                            top_timestamp: r.top_timestamp,
                            status: r.status,
                            used_num: e
                        })
                    } catch (error) {
                        
                    }
                    setLoading(false)

                }} />
            )
        },
        {
            title: '是否置顶',
            dataIndex: 'top_timestamp',
            render: (v,r) => (
                <Switch checked={parseInt(v) !== 0} onChange={async i => {
                    setLoading(true)
                    try {
                        r.top_timestamp = i === true ? new Date().getTime():0
                        await http.post(apis.ADMIN_TEMPLATE_UPDATE,r)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '是否上架',
            dataIndex: 'status',
            render: (v,r) => (
                <Switch checked={v === 2} onChange={async i => {
                    setLoading(true)
                    try {
                        r.status = i === true ? 2:1
                        await http.post(apis.ADMIN_TEMPLATE_UPDATE,r)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    <Button loading={loading} type="link" onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await doDelete([r.id])
                                    await loadData(search)
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }}>删除</Button>
                </Space>
            )
        }  
    ];

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <Modal forceRender open={isModalOpen} onCancel={() => setIsModalOpen(false)} okButtonProps={{loading}} onOk={doCreate}>
                <div style={{padding: 12}}>
                    <Form labelCol={{span:4}} form={form} autoComplete="off">
                        <Form.Item label="编号" name="code" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="关键词" />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Space>
                <Button disabled={selectedRowKeys.length === 0} loading={loading} onClick={async () => {
                    Modal.confirm({
                        content: "确定删除吗?",
                        onOk: async () => {
                            setLoading(true)
                            try {
                                await http.post(apis.ADMIN_TEMPLATE_DELETE,{ids: selectedRowKeys})
                                await loadData(search)
                                setSelectedRowKeys([])
                                message.success("操作成功")
                            } catch (error) {
                                
                            }
                            setLoading(false)
                        }
                    })
                }} icon={<DeleteOutlined />}>批量删除</Button>
            </Space>
            <div style={{height: 12}}></div>
            <Table 
                scroll={{
                    y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange:(v)=>{
                        setSelectedRowKeys(v)
                    }
                }} 
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                        setSelectedRowKeys([])
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index