import React,{ useRef, useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Image,Button, Form,Modal, Space, DatePicker, Input } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
const { RangePicker } = DatePicker


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20, start_date: '', end_date: '', status: 1})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [cuidanOpen, setCuidanOpen] = useState(false)
    const [cuidanValue, setCuidanValue] = useState('')
    const [fahuoValue, setFahuoValue] = useState('')
    const [fahuoOpen, setFahuoOpen] = useState(false)
    const tempOrderCache = useRef({})

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.WORKS_LIST, s)
            data.list = data.list || []
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        loadData(search).then(r => {
            setLoading(false)
        })


        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])


    let columns = [
        {
            title: '头像',
            dataIndex: 'user_info',
            render:(v)=>(
                v.avatar && <Image src={v.avatar} style={{width: 50, height:50}}></Image>
            )
        },
        {
            title: '用户名',
            dataIndex: 'user_info',
            render:(v)=>(
                <span>{v.nickname}</span>
            )
        },
        {
            title: '作品',
            dataIndex: 'path',
            render: (v) => (
                <Image width={80} height={80} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '时间',
            dataIndex: 'created_at',
            render: (v) => (
                <div>{moment(v).format("YYYY-MM-DD HH:mm:ss")}</div>
            )
        } 
    ];

    return (
        <div>
            <Modal title="发货" okButtonProps={{loading}} onOk={async () => {
                if(!fahuoValue){
                    return
                }
                setLoading(true)
                try {
                    await http.post(`${apis.ORDER_SHIPMENT}?order_no=${tempOrderCache.current.order_no}&logistics_no=${fahuoValue}`)
                } catch (error) {
                    
                }
                setLoading(false)
                setFahuoOpen(false)
                setFahuoValue('')
                loadData(search)
            }} onCancel={()=>{
                setFahuoOpen(false)
                tempOrderCache.current = {}
                setFahuoValue('')
            }} open={fahuoOpen}>
                <Input value={fahuoValue} onChange={e => {
                    setFahuoValue(e.target.value)
                }} placeholder="请输入物流单号" maxLength={100} />
            </Modal>
            <Modal title="催单回复" okButtonProps={{loading}} onOk={async () => {
                if(!cuidanValue){
                    return
                }
                setLoading(true)
                try {
                    await http.post(apis.ORDER_CUI,{
                        order_no: tempOrderCache.current.order_no,
                        uid: tempOrderCache.current.uid,
                        title: "催单回复",
                        msg_type: "cuidan",
                        content: cuidanValue
                    })
                } catch (error) {
                    
                }
                setLoading(false)
                setCuidanOpen(false)
                setCuidanValue('')
                loadData(search)
            }} onCancel={()=>{
                setCuidanOpen(false)
                tempOrderCache.current = {}
                setCuidanValue('')
            }} open={cuidanOpen}>
                <TextArea value={cuidanValue} onChange={e => {
                    setCuidanValue(e.target.value)
                }} placeholder="请输入内容" maxLength={100} />
            </Modal>
            <span style={{display: "none"}}>{update}</span>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Form layout="inline" onFinish={async (values)=>{
                    setLoading(true)
                    let start_date = ''
                    let end_date = ''
                    try {
                        start_date = values.dates[0].format("YYYY-MM-DD HH:mm:ss")
                        end_date = values.dates[1].format("YYYY-MM-DD HH:mm:ss")
                    } catch (error) {
                        
                    }
                    let cpSearch = cloneDeep(search)
                        cpSearch.page = 1
                        cpSearch.start_date = start_date
                        cpSearch.end_date = end_date
                        setSearch(cpSearch)
                        await loadData(cpSearch)
                    setLoading(false)
                }}>
                    <Form.Item name={"dates"}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset">重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
            <div style={{height: 12}}></div>
            <Table 
                scroll={{
                    y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }} 
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index