import React,{ useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Button, DatePicker, Form, Input,Modal, message, Switch, InputNumber, Radio  } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
const { RangePicker } = DatePicker;


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const expireType = Form.useWatch("expire_type", form)

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.ADMIN_COUPON_LIST, s)
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        loadData(search).then(r=>{
            setLoading(false)
        })

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])


    const doCreate = async () => {
        try {
            let values = await form.validateFields()
            let cpVaules = cloneDeep(values)
            cpVaules.discount_price_str = parseFloat(cpVaules.discount_price).toFixed(2)
            cpVaules.overflow_price_str = parseFloat(cpVaules.overflow_price).toFixed(2)

            delete cpVaules.overflow_price
            delete cpVaules.discount_price
            if(cpVaules.expire_type === 1){
                cpVaules.start_at = cpVaules.timeInfo[0]
                cpVaules.end_at = cpVaules.timeInfo[1]
                cpVaules.dura = 0
            }else{
                cpVaules.dura = parseInt(cpVaules.timeInfo*3600*24)
                cpVaules.start_at = null 
                cpVaules.end_at = null
            }
            setLoading(true)
            try {
                await http.post(apis.ADMIN_COUPON_UPDATE,cpVaules)
                message.success("操作成功")
                setIsModalOpen(false)
                await loadData(search)
            } catch (error) {
                
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const doEdit = async (v) => {
        try {
            form.resetFields()
            let cpVaules = cloneDeep(v)
            if(cpVaules.expire_type === 1){
                cpVaules.timeInfo = [moment(cpVaules.start_at),moment(cpVaules.end_at)]
            }else{
                cpVaules.timeInfo = parseInt(cpVaules.dura/(3600*24))
            }
            cpVaules.discount_price = (cpVaules.discount_price/100).toFixed(2)
            cpVaules.overflow_price = (cpVaules.overflow_price/100).toFixed(2)
            console.log(cpVaules)
            form.setFieldsValue(cpVaules)
            setIsModalOpen(true)
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '优惠券名称',
            dataIndex: 'name'
        },
        {
            title: '面额',
            dataIndex: 'discount_price',
            render: (v) => (
                <span>{(v/100).toFixed(2)}元</span>
            )
        },
        {
            title: '使用门槛',
            dataIndex: 'overflow_price',
            render: (v) => (
                <span>{(v/100).toFixed(2)}元</span>
            )
        },
        {
            title: '有效时间',
            dataIndex: 'a',
            render: (v,r) => {
                if(r.expire_type === 1){
                    return `${moment(r.start_at).format("YYYY-MM-DD HH:mm:ss")} - ${moment(r.end_at).format("YYYY-MM-DD HH:mm:ss")}`
                }
                return `领取后${parseInt(r.dura/(3600*24))}天`
            }
        },
        {
            title: '是否激活',
            dataIndex: 'status',
            render: (v,r) => (
                <Switch checked={v === 1} onChange={async i => {
                    setLoading(true)
                    try {
                        r.status = i === true ? 1:2
                        await http.post(`${apis.ADMIN_COUPON_STATUS_UPDATE}?id=${r.id}&status=${r.status}`)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Button loading={loading} danger type="link" onClick={() => doEdit(r)}>编辑</Button>
            )
        }  
    ];

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <Modal title="优惠券" forceRender open={isModalOpen} onCancel={() => setIsModalOpen(false)} okButtonProps={{loading}} onOk={doCreate}>
                <div style={{padding: 12}}>
                    <Form labelCol={{span:5}} form={form} autoComplete="off">
                        <Form.Item hidden name="id">
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="名称" name="name" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="请输入优惠券名称" />
                        </Form.Item>
                        <Form.Item label="面值" name="discount_price">
                            <InputNumber style={{width: 200}} />
                        </Form.Item>
                        <Form.Item label="使用门槛" name="overflow_price" rules={[{required: true,message: '请选择'}]}>
                            <InputNumber style={{width: 200}} />
                        </Form.Item>
                        <Form.Item initialValue={1} label="有效期" name="expire_type" rules={[{required: true,message: '请上传'}]}>
                            <Radio.Group>
                                <Radio value={1}>绝对时间</Radio>
                                <Radio value={2}>相对时间</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            expireType === 1 ? <Form.Item label="时间" name="timeInfo" rules={[{required: true,message: '请选择'}]}>
                                <RangePicker showTime />
                            </Form.Item>:<Form.Item label="领取后()天" name="timeInfo" rules={[{required: true,message: '请选择'}]}>
                                <InputNumber style={{width: 200}} /> 
                            </Form.Item>
                        }
                    </Form>
                </div>
            </Modal>
            <Table 
                scroll={{
                    y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index