import React,{ useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Image,Button,Modal, message, Space,Form,Input  } from 'antd';
import { cloneDeep } from "lodash"
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const n = useNavigate()

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.ADMIN_GOODS_LIST, s)
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        loadData(search).then(r=>{
            setLoading(false)
        })

        

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])

    const create = () => {
       n('/goods/create')
    }



    const doEdit = async (r) => {
        localStorage.setItem(`goods_info_${r.id}`,JSON.stringify(r))
        n(`/goods/create?id=${r.id}`)
    }

    const doDelete = async (ids) => {
        try {
            await http.post(apis.ADMIN_GOODS_DELETE,{ids: ids})
        } catch (error) {
            
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'hash_id'
        },
        {
            title: '商品',
            dataIndex: 'cat_id',
            render: (v) => (
                <span>{[{"label":"DIY手机壳","value": 1},{"label":"拍立得","value": 2}].find(i => i.value === v)?.label}</span>
            )
        },
        {
            title: '品牌',
            dataIndex: 'brand'
        },
        {
            title: '型号',
            dataIndex: 'model'
        },
        {
            title: '材质',
            dataIndex: 'material'
        },
        {
            title: '颜色',
            dataIndex: 'color'
        },
        {
            title: '模型',
            dataIndex: 'bg_img',
            render: (v) => (
                <Image width={80} height={80} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '产品图',
            dataIndex: 'imgs',
            render: (v) => {
                try {
                    return <div style={{display: "flex", justifyContent: "space-between"}}>
                        {
                            v.split(",").map((i,dx) => (
                                <Image key={dx} width={40} height={40} src={process.env.REACT_APP_OSS_URL+"/"+i} />
                            ))
                        }
                    </div>
                } catch (error) {
                    
                }
                
            }
        },
        {
            title: '详情图',
            dataIndex: 'detail_img',
            render: (v) => {
                try {
                    return <div style={{display: "flex", justifyContent: "space-between"}}>
                        {
                            v.split(",").map((i,dx) => (
                                <Image key={dx} width={40} height={40} src={process.env.REACT_APP_OSS_URL+"/"+i} />
                            ))
                        }
                    </div>
                } catch (error) {
                    
                }
            }
        },
        {
            title: '价格',
            dataIndex: 'price_str',
            render: (v) => (
                <span>¥ {v}</span>
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    <Button loading={loading} danger type="link" onClick={() => doEdit(r)}>编辑</Button>
                    <Button loading={loading} type="link" onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await doDelete([r.id])
                                    await loadData(search)
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }}>删除</Button>
                </Space>
            )
        }  
    ];

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <div style={{display: "flex",justifyContent: "space-between"}}>
                <Form layout="inline" onFinish={async (v) => {
                    setLoading(true)
                    let cpSearch = cloneDeep(search)
                    cpSearch.page = 1
                    cpSearch.txt = v.txt
                    setSearch(cpSearch)
                    try {
                        await loadData(cpSearch)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }}>
                    <Form.Item name={"txt"}>
                        <Input placeholder="输入关键字" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={loading}>搜索</Button>
                    </Form.Item>
                </Form>
                <Space>
                    <Button disabled={selectedRowKeys.length === 0} loading={loading} onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await http.post(apis.ADMIN_GOODS_DELETE,{ids: selectedRowKeys})
                                    await loadData(search)
                                    setSelectedRowKeys([])
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }} icon={<DeleteOutlined />}>批量删除</Button>
                    <Button type="primary" loading={loading} onClick={create}>新建</Button>
                </Space>
            </div>
            
            <div style={{height: 12}}></div>
            <Table 
                scroll={{
                    y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange:(v)=>{
                        setSelectedRowKeys(v)
                    }
                }} 
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                        setSelectedRowKeys([])
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index