export const LOGIN = `/o/open/login`
export const BANNER_LIST = `/o/api/banner/list`
export const BANNER_CREATE = `/o/api/banner/create`
export const BANNER_DELETE = `/o/api/banner/delete`

export const PATTERN_LIST = `/o/api/pattern/list`
export const PATTERN_CREATE = `/o/api/pattern/create`
export const PATTERN_DELETE = `/o/api/pattern/delete`
export const PATTERN_CATS = `/c/open/pattern/cats`
export const PATTERN_CATS_CREATE = `/o/api/pattern/cats/create`
export const PATTERN_CATS_DELETE = `/o/api/pattern/cats/delete`
export const TEMPLATE_CATS_CREATE = `/o/api/template/cats/create`
export const TEMPLATE_CATS_DELETE = `/o/api/template/cats/delete`

export const ADMIN_INFO = `/o/api/admin/info`
export const ADMIN_UPDATE = `/o/api/admin/update`
export const ADMIN_SET_PASSWORD = `/o/api/admin/password/reset`
export const ADMIN_LIST = `/o/api/admin/list`
export const ADMIN_CREATE = `/o/api/admin/create`
export const ADMIN_DELETE = `/o/api/admin/delete`
export const ADMIN_DISABLED = `/o/api/admin/disabled`
export const ADMIN_INIT_PASSWORD = `/o/api/admin/password/init`
export const ADMIN_GOODS_OPTIONS = `/o/api/admin/goods/options`
export const ADMIN_GOODS_LIST = `/o/api/admin/goods/list`
export const ADMIN_GOODS_CREATE = `/o/api/admin/goods/create`
export const ADMIN_GOODS_DELETE = `/o/api/admin/goods/delete`
export const ADMIN_TEMPLATE_LIST = `/o/api/admin/template/list`
export const ADMIN_TEMPLATE_CATS = `/o/api/admin/template/cats`
export const ADMIN_TEMPLATE_UPDATE = `/o/api/admin/template/update`
export const ADMIN_TEMPLATE_DELETE = `/o/api/admin/template/delete`
export const ADMIN_COUPON_LIST = `/o/api/coupon/list`
export const ADMIN_COUPON_UPDATE = `/o/api/coupon/create`
export const ADMIN_COUPON_STATUS_UPDATE = `/o/api/coupon/status/update`
export const ORDER_BATCH_TIP = `/o/api/order/batch/tip`
export const ORDER_REFUND = `/o/api/order/cancel`
export const ORDER_CUI = `/o/api/order/cui`
export const ORDER_PRODUCTION = `/o/api/order/production`
export const ORDER_SHIPMENT = `/o/api/order/shipment`


export const WORKS_LIST = `/o/api/works/list`
export const ORDER_LIST = `/o/api/order/list`
export const ORDER_FREE = `/o/api/order/free`
export const E_PUBLISH = `/o/api/order/evaluate/publish`

export const ORDER_FREIGHT_LIST = `/b/api/order/freight/list`

export const ORDER_STATUS = `/o/open/order/status`
export const UPLOAD = `/o/api/tool/upload`
export const STATUS_TURNOVER = `/b/api/order/status/turnover`


