import React,{ useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Image,Button, Select, Form, Input,Modal, message, Switch, Space, InputNumber  } from 'antd';
import { cloneDeep } from "lodash"
import { DeleteOutlined } from '@ant-design/icons';
import Upload from '../../components/upload'


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [cats, setCats] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [form] = Form.useForm()

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.PATTERN_LIST, s)
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        http.post(apis.PATTERN_CATS).then(r => {
            for(let i=0;i<r.length;i++){
                r[i].label = r[i].txt
                r[i].value = r[i].id
            }
            setCats(r)
            loadData(search).then(r=>{
                setLoading(false)
            })
        })

        

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])

    const create = () => {
        form.resetFields()
        setIsModalOpen(true)
    }

    const doCreate = async () => {
        try {
            let values = await form.validateFields()
            let cpVaules = cloneDeep(values)
            cpVaules.img_url = cpVaules.img_url[0].path
            cpVaules.small_cover = cpVaules.small_cover[0].path
            if(cpVaules.key_words){
                cpVaules.key_words = cpVaules.key_words.join(",")
            }else{
                cpVaules.key_words = ''
            }
            cpVaules.status = cpVaules.status === true ? 2:1
            cpVaules.top_timestamp = cpVaules.top_timestamp === true ? new Date().getTime():0
            setLoading(true)
            try {
                await http.post(apis.PATTERN_CREATE,cpVaules)
                message.success("操作成功")
                setIsModalOpen(false)
                await loadData(search)
            } catch (error) {
                
            }
            setLoading(false)
        } catch (error) {
            
        }
    }

    const doEdit = async (v) => {
        try {
            form.resetFields()
            let cpVaules = cloneDeep(v)
            cpVaules.status = cpVaules.status === 1 ? false:true
            cpVaules.top_timestamp = v.top_timestamp === 0 ? false:true
            cpVaules.img_url = [{path: v.img_url,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+v.img_url}]
            cpVaules.small_cover = [{path: v.small_cover,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+v.small_cover}]
            if(cpVaules.key_words){
                cpVaules.key_words = cpVaules.key_words.split(",")
            }else{
                cpVaules.key_words = []
            }
            form.setFieldsValue(cpVaules)
            setIsModalOpen(true)
        } catch (error) {
            console.log(error)
        }
    }

    const doDelete = async (ids) => {
        try {
            await http.post(apis.PATTERN_DELETE,{ids: ids})
        } catch (error) {
            
        }
    }

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
        },
        {
            title: '缩略图',
            dataIndex: 'small_cover',
            render: (v) => (
                <Image width={80} height={80} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '分类',
            dataIndex: 'cat_id',
            render: (v) => (
                <div>{cats.find(i => i.id === v)?.txt}</div>
            )
        },
        {
            title: '关键词',
            dataIndex: 'key_words',
        },
        {
            title: '是否上架',
            dataIndex: 'status',
            render: (v,r) => (
                <Switch checked={v === 2} onChange={async i => {
                    setLoading(true)
                    try {
                        r.status = i === true ? 2:1
                        await http.post(apis.PATTERN_CREATE,r)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '是否置顶',
            dataIndex: 'top_timestamp',
            render: (v,r) => (
                <Switch checked={parseInt(v) !== 0} onChange={async i => {
                    setLoading(true)
                    try {
                        r.top_timestamp = i === true ? new Date().getTime():0
                        await http.post(apis.PATTERN_CREATE,r)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    <Button loading={loading} danger type="link" onClick={() => doEdit(r)}>编辑</Button>
                    <Button loading={loading} type="link" onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await doDelete([r.id])
                                    await loadData(search)
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }}>删除</Button>
                </Space>
            )
        }  
    ];

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <Modal forceRender open={isModalOpen} onCancel={() => setIsModalOpen(false)} okButtonProps={{loading}} onOk={doCreate}>
                <div style={{padding: 12}}>
                    <Form labelCol={{span:4}} form={form} autoComplete="off">
                        <Form.Item hidden name="id">
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="编号" name="code" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="请填入编号" />
                        </Form.Item>
                        <Form.Item label="关键词" name="key_words">
                            <Select mode="tags" placeholder="请输入关键词" />
                        </Form.Item>
                        <Form.Item label="选择分类" name="cat_id" rules={[{required: true,message: '请选择'}]}>
                            <Select placeholder="请选择分类" options={cats} />
                        </Form.Item>
                        <Form.Item label="缩略图" name="small_cover" rules={[{required: true,message: '请上传'}]}>
                            <Upload />
                        </Form.Item>
                        <Form.Item label="原图" name="img_url" rules={[{required: true,message: '请上传'}]}>
                            <Upload />
                        </Form.Item>
                        <Form.Item initialValue={false} valuePropName="checked" label="是否上架" name="status">
                            <Switch />
                        </Form.Item>
                        <Form.Item initialValue={false} valuePropName="checked" label="是否置顶" name="top_timestamp">
                            <Switch />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <div style={{display: "flex",justifyContent: "space-between"}}>
                <Form layout="inline" onFinish={async (v) => {
                    setLoading(true)
                    let cpSearch = cloneDeep(search)
                    cpSearch.page = 1
                    cpSearch.txt = v.txt
                    setSearch(cpSearch)
                    try {
                        await loadData(cpSearch)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }}>
                    <Form.Item name={"txt"}>
                        <Input placeholder="输入关键字" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={loading}>搜索</Button>
                    </Form.Item>
                </Form>
                <Space>
                    <Button disabled={selectedRowKeys.length === 0} loading={loading} onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await http.post(apis.PATTERN_DELETE,{ids: selectedRowKeys})
                                    await loadData(search)
                                    setSelectedRowKeys([])
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }} icon={<DeleteOutlined />}>批量删除</Button>
                    <Button type="primary" loading={loading} onClick={create}>新建</Button>
                </Space>
            </div>
            
            <div style={{height: 12}}></div>
            <Table 
                scroll={{
                    y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange:(v)=>{
                        setSelectedRowKeys(v)
                    }
                }} 
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                        setSelectedRowKeys([])
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index