import React, { useState } from "react";


const Index = () => {
    useState(()=>{window.location.replace("/banner")},[])
    return (
        <div>Home</div>
    )
}

export default Index