import Login from '../pages/login'
import Home from '../pages/home'
import Me from '../pages/me'
import Pattern from '../pages/pattern'
import User from '../pages/user'
import Banner from '../pages/banner'
import Goods from '../pages/goods'
import Template from '../pages/template'
import Coupon from '../pages/coupon'
import Order from '../pages/order'
import Ai from '../pages/ai'
import PatternCats from '../pages/pattern_cats'
import TemplateCats from '../pages/template_cats'
import CreateGoods from '../pages/goods/create'
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';

const style = {
    margin: '16px 0',
}

const data = [
    {
        path: "/",
        component: <Home />,
        name: "",
        icon: <HomeOutlined />,
        layout: true,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>首页</Breadcrumb.Item>
        </Breadcrumb>
    },
    {
        path: "/banner",
        name: "Banner管理",
        // icon: <FileImageOutlined />,
        layout: true,
        component: <Banner />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>Banner管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/pattern",
        name: "素材管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Pattern />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>素材管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1,2]
    },
    {
        path: "/pattern/cats",
        name: "素材分类",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <PatternCats />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>素材分类</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1,2]
    },
    {
        path: "/user",
        name: "权限管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <User />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>权限管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/goods",
        name: "商品管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Goods />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>商品管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/goods/create",
        name: "",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <CreateGoods />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>商品管理</Breadcrumb.Item>
            <Breadcrumb.Item>添加商品</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/template/cats",
        name: "模板分类",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <TemplateCats />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>模板分类</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/template",
        name: "模板管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Template />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>模板管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/coupon",
        name: "优惠券管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Coupon />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>优惠券管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/order",
        name: "订单管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Order />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>订单管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/ai",
        name: "网友AI绘画管理",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Ai />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>网友AI绘画管理</Breadcrumb.Item>
        </Breadcrumb>,
        roleIds: [1]
    },
    {
        path: "/me",
        name: "",
        // icon: <TransactionOutlined />,
        layout: true,
        component: <Me />,
        breadcrumb: <Breadcrumb style={style}>
            <Breadcrumb.Item>个人中心</Breadcrumb.Item>
        </Breadcrumb>
    },
    {
        path: "/login",
        component: <Login />,
        name: "",
        icon: <HomeOutlined />,
        layout: false
    }
]

export default data