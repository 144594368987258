import { Button, Table , Image, Switch, Space, Modal, Form, Input, message, InputNumber} from "antd";
import React, { useEffect, useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import Upload from '../../components/upload';
import { cloneDeep } from 'lodash'

const Index = () => {
    const [search, setSearch] = useState({page: 1,page_size: 20})
    const [resData, setResData] = useState({})
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(new Date().getTime())
    const [form] = Form.useForm()


    const loadData = async (s) => {
        try {
            let ret = await http.post(apis.BANNER_LIST,s)
            setResData(ret)
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        setLoading(true)
        loadData(search).then(r => {
            setLoading(false)
        })

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }
        // eslint-disable-next-line
    },[])

    const columns = [
        {
            title: '标题',
            dataIndex: 'title'
        },
        {
            title: '图片',
            dataIndex: 'img',
            width: 269,
            render: (v) => (
                <Image width={249} height={73} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '链接',
            dataIndex: 'link'
        },
        {
            title: '序号',
            dataIndex: 'no'
        },
        {
            title: '上架',
            dataIndex: 'status',
            render: (v,r) => (
                <Switch checked={v === 2} onChange={async v => {
                    setLoading(true)
                    try {
                        let cpV = cloneDeep(r)
                        cpV.status = v === true ? 2:1
                        await http.post(apis.BANNER_CREATE,cpV)
                        await loadData(search)
                    } catch (error) {
                        
                    }
                    setLoading(false)
                }} />
            )
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    <Button type="link" onClick={() => edit(r)}>编辑</Button>
                    <Button type="link" danger onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                await http.post(apis.BANNER_DELETE,{id: r.id})
                                await loadData(search)
                            }
                        })
                    }}>删除</Button>
                </Space>
            )
        }
    ]
    const create = () => {
        form.resetFields()
        setOpen(true)
    }

    const edit = (v) => {
        form.resetFields()
        let cpV = cloneDeep(v)
        cpV.img = [{path: v.img,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+v.img}]
        cpV.status = v.status === 1 ? false:true
        form.setFieldsValue(cpV)
        setOpen(true)
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            let values = await form.validateFields()
            values.img = values.img[0].path
            values.status = values.status === false ? 1:2
            await http.post(apis.BANNER_CREATE,values)
            setOpen(false)
            await loadData(search)
            message.success("操作成功")
        } catch (error) {
            
        }
        setLoading(false)
    }

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <Modal onCancel={() => setOpen(false)} okButtonProps={{loading}} onOk={onSubmit} open={open}>
                <div style={{padding: 12}}>
                    <Form labelCol={{span:4}} form={form}>
                        <Form.Item name="id" hidden>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="title" label="标题" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="输入标题名称" />
                        </Form.Item>
                        <Form.Item name="link" label="链接地址" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="输入链接" />
                        </Form.Item>
                        <Form.Item name="no" label="序号" rules={[{required: true,message: '请输入'}]}>
                            <InputNumber style={{width: 200}} placeholder="序号1为默认显示" />
                        </Form.Item>
                        <Form.Item name="img" rules={[{required: true,message: '请上传'}]}>
                            <Upload />
                        </Form.Item>
                        <Form.Item initialValue={false} name="status" valuePropName="checked" label="上架">
                            <Switch />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Button type="primary" onClick={create} loading={loading}>新建</Button>
            <div style={{height: 12}}></div>
            <Table scroll={{
                y: `calc(100vh - 340px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
            }} rowKey={`id`} columns={columns} dataSource={resData?.list} pagination={{
                total: resData.total,
                pageSize: resData.page_size,
                onChange: async (page) => {
                    let cpSearch = cloneDeep(search)
                    cpSearch.page = page
                    setSearch(cpSearch)
                    setLoading(true)
                    await loadData(cpSearch)
                    setLoading(false)
                },
                current: resData?.page,
            }} />
        </div>
    )
}

export default Index