import React,{ useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Button, Form, Input,Modal, message, Space, InputNumber  } from 'antd';




const Index = () => {
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(new Date().getTime())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [cats, setCats] = useState([])
    const [form] = Form.useForm()

    const loadData = async () => {
        try {
            let ret = await http.post(apis.PATTERN_CATS)
            setCats(ret)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)
        loadData().then(r => {
            setLoading(false)
        })
        
        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])

    const doCreate = async () => {
        try {
            let values = await form.validateFields()
            setLoading(true)
            try {
                await http.post(apis.PATTERN_CATS_CREATE,values)
                message.success("操作成功")
                setIsModalOpen(false)
                await loadData()
            } catch (error) {
                
            }
            setLoading(false)
        } catch (error) {
            
        }
    }

    const doEdit = async (v) => {
        try {
            form.resetFields()
            form.setFieldsValue(v)
            setIsModalOpen(true)
        } catch (error) {
            console.log(error)
        }
    }

    const doDelete = async (id) => {
        try {
            await http.post(`${apis.PATTERN_CATS_DELETE}?id=${id}`)
        } catch (error) {
            
        }
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
        },
        {
            title: '名称',
            dataIndex: 'txt'
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    <Button loading={loading} danger type="link" onClick={() => doEdit(r)}>编辑</Button>
                    <Button loading={loading} type="link" onClick={async () => {
                        Modal.confirm({
                            content: "确定删除吗?",
                            onOk: async () => {
                                setLoading(true)
                                try {
                                    await doDelete(r.id)
                                    await loadData()
                                    message.success("操作成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }
                        })
                    }}>删除</Button>
                </Space>
            )
        }  
    ];

    return (
        <div>
            <span style={{display: "none"}}>{update}</span>
            <Modal title="新增/编辑" forceRender open={isModalOpen} onCancel={() => setIsModalOpen(false)} okButtonProps={{loading}} onOk={doCreate}>
                <div style={{padding: 12}}>
                    <Form labelCol={{span:4}} form={form} autoComplete="off">
                        <Form.Item hidden name="id">
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="名称" name="txt" rules={[{required: true,message: '请输入'}]}>
                            <Input placeholder="请填入名称" />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Button onClick={() => {
                setIsModalOpen(true)
                form.resetFields()
            }}>新增</Button>
            <div style={{height: 12}}></div>
            <Table 
                scroll={{
                    y: `calc(100vh - 280px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowKey={`id`} 
                columns={columns} 
                loading={loading} 
                dataSource={cats || []} 
                pagination={false} />
        </div>
    )
}

export default Index