import http from '../../lib/http'
import * as apis from '../../lib/api'
import { useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Space, message } from 'antd'
import Upload from '../../components/upload'
import { cloneDeep } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

const ModelConfig = (props) => {
    const {onChange, value = {}} = props
    return (
        <Space>
            <div style={{display: "flex", alignItems: "center"}}>
                <span>宽度：</span>
                <InputNumber value={value.canvasWidth} onChange={v => {
                    let cpV = cloneDeep(value)
                    cpV.canvasWidth = v
                    onChange(cpV)
                }} />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <span>高度：</span>
                <InputNumber value={value.canvasHeight} onChange={v => {
                    let cpV = cloneDeep(value)
                    cpV.canvasHeight = v
                    onChange(cpV)
                }} />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{flexShrink: 0}}>颜色色值：</span>
                <input type="color" style={{width: 50}} value={value.color} onChange={e => {
                    let cpV = cloneDeep(value)
                    cpV.color = e.target.value
                    onChange(cpV)
                }} />
            </div>
        </Space>
    )
}

const Index = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const catId = Form.useWatch("cat_id",form)
    const n = useNavigate()
    const l = useLocation()

    useEffect(()=>{
        let params = new URLSearchParams(l.search)
        if(params.get('id')){
            let info = localStorage.getItem(`goods_info_${params.get('id')}`)
            info = JSON.parse(info)
            info.bg_img = [{path: info.bg_img,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+info.bg_img}]
            //info.detail_img = [{path: info.detail_img,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+info.detail_img}]
            info.model_config = JSON.parse(info.model_config)
            if(info.imgs){
                let imgs = []
                info.imgs = info.imgs.split(",")
                for(let i=0;i<info?.imgs?.length;i++){
                    let item = info?.imgs[i]
                    imgs.push({path: item,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+item})
                }
                info.imgs = imgs
            }else{
                info.imgs = []
            }
            if(info.detail_img){
                console.log(info.detail_img)
                let detail_img = []
                info.detail_img = info.detail_img.split(",")
                for(let i=0;i<info?.detail_img?.length;i++){
                    let item = info?.detail_img[i]
                    detail_img.push({path: item,thumbUrl: process.env.REACT_APP_OSS_URL+"/"+item})
                }
                info.detail_img = detail_img
            }else{
                info.detail_img = []
            }
            info.price_float = parseFloat(info.price_str)
            form.setFieldsValue(info)
        }
        // eslint-disable-next-line
    },[])

    const onSubmit = async (values) => {
        let cpValues = cloneDeep(values)
        let imgs = []
        let detail_img = []
        for(let i=0;i<cpValues.imgs.length;i++){
            let item = cpValues.imgs[i]
            imgs.push(item.path)
        }
        for(let i=0;i<cpValues.detail_img.length;i++){
            let item = cpValues.detail_img[i]
            detail_img.push(item.path)
        }
        cpValues.imgs = imgs.join(",")
        cpValues.bg_img = cpValues.bg_img[0].path
        cpValues.detail_img = detail_img.join(",")
        cpValues.model_config = JSON.stringify(cpValues.model_config)
        setLoading(true)
        try {
            await http.post(apis.ADMIN_GOODS_CREATE,cpValues)
            message.success("添加成功")
            n("/goods")
        } catch (error) {
            
        }
        setLoading(false)
    }


    return (
        <div style={{width: 520}}>
            <Form form={form} labelCol={{span:5}} onFinish={onSubmit}>
                <Form.Item hidden name="id">
                    <InputNumber />
                </Form.Item>
                <Form.Item initialValue={1} label="选择商品" name="cat_id">
                    <Select placeholder="选择商品" options={[{"label":"DIY手机壳","value": 1},{"label":"拍立得","value": 2}]} />
                </Form.Item>
                <Form.Item hidden={catId === 2} rules={[{required: catId === 1,message: '请输入'}]} label="输入品牌" name="brand">
                    <Input placeholder="输入品牌名称" />
                </Form.Item>
                <Form.Item hidden={catId === 2} rules={[{required: catId === 1,message: '请输入'}]} label="输入型号" name="model">
                    <Input placeholder="输入型号名称" />
                </Form.Item>
                <Form.Item hidden={catId === 2} rules={[{required: catId === 1,message: '请输入'}]} label="输入材质" name="material">
                    <Input placeholder="输入材质名称" />
                </Form.Item>
                <Form.Item hidden={catId === 2} rules={[{required: catId === 1,message: '请输入'}]} label="输入颜色" name="color">
                    <Input placeholder="输入颜色名称" />
                </Form.Item>
                <Form.Item label="上传模型" rules={[{required: true,message: '请上传'}]} name="bg_img">
                    <Upload />
                </Form.Item>
                <Form.Item label="模型配置" initialValue={{canvasWidth: 280,canvasHeight: 519,color: "#FFFFFF"}} rules={[{required: true,message: '请填写'}]} name="model_config">
                    <ModelConfig />
                </Form.Item>
                <Form.Item label="上传产品图" rules={[{required: true,message: '请上传'}]} name="imgs">
                    <Upload maxCount={5} />
                </Form.Item>
                <Form.Item label="商品详情" rules={[{required: true,message: '请上传'}]} name="detail_img">
                    <Upload maxCount={5} />
                </Form.Item>
                <Form.Item label="价格" rules={[{required: true,message: '请输入'}]}  name="price_float">
                    <InputNumber min={0.01} style={{width: 200}} placeholder='输入价格' />
                </Form.Item>
                <Form.Item>
                    <Space style={{marginLeft: '7em'}}>
                        <Button loading={loading} type="primary" htmlType="submit">确定</Button>
                        <Button loading={loading} onClick={() => window.history.back()}>取消</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Index