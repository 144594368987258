import React,{ useRef, useState } from "react";
import http from '../../lib/http'
import * as apis from '../../lib/api'
import { Table,Image,Button, Form, Tabs,Modal, message, Rate, Space, DatePicker, Input, Switch } from 'antd';
import { cloneDeep } from "lodash"
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
const { RangePicker } = DatePicker


const Index = () => {
    const [search, setSearch] = useState({page: 1, page_size: 20, start_date: '', end_date: '', status: 2})
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState({list: []})
    const [update, setUpdate] = useState(new Date().getTime())
    const [cuidanOpen, setCuidanOpen] = useState(false)
    const [cuidanValue, setCuidanValue] = useState('')
    const [fahuoValue, setFahuoValue] = useState('')
    const [fahuoOpen, setFahuoOpen] = useState(false)
    const tempOrderCache = useRef({})
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const loadData = async (s) => {
        try {
            let data = await http.post(apis.ORDER_LIST, s)
            data.list = data.list || []
            for(let i=0;i<data.list.length;i++){
                data.list[i].address = JSON.parse(data.list[i].address)
                data.list[i].goods_info = JSON.parse(data.list[i].goods_info)
                if(data.list[i].order_evaluate.id !== 0){
                    data.list[i].order_evaluate.media = JSON.parse(data.list[i].order_evaluate.media)
                }
            }
            console.log(data)
            
            setResponse(data)
        } catch (error) {
            
        }
    }

    useState(()=>{
        setLoading(true)

        loadData(search).then(r => {
            setLoading(false)
        })


        

        let onresize = () => {
            setUpdate(new Date().getTime())
        }

        window.addEventListener("resize",onresize)
        return () => {
            window.removeEventListener("resize",onresize)
        }

        // eslint-disable-next-line
    },[])


    const doPublish = async (orderNo) => {
        try {
            await http.post(`${apis.E_PUBLISH}?order_no=${orderNo}`)
        } catch (error) {
            
        }
    }

    let columns = [
        {
            title: '订单号',
            dataIndex: 'order_no'
        },
        {
            title: '缩略图',
            dataIndex: 'cover',
            render: (v) => (
                <Image width={80} height={80} src={process.env.REACT_APP_OSS_URL+"/"+v} />
            )
        },
        {
            title: '姓名',
            dataIndex: 'address',
            render: (v,r,dx) => (
                <div>{r.address.name}</div>
            )
        },
        {
            title: '手机',
            dataIndex: 'address',
            render: (v,r,dx) => (
                <div>{r.address.phone}</div>
            )
        },
        {
            title: '商品',
            dataIndex: 'address',
            render: (v,r,dx) => (
                <div>{r.name},{r.goods_info.brand},{r.goods_info.model},{r.goods_info.material},{r.goods_info.color}</div>
            )
        },
        {
            title: '金额',
            dataIndex: 'real_price_str',
            render: (v) => (
                <div>¥{v}</div>
            )
        },
        {
            title: '收货地址',
            dataIndex: 'address',
            render: (v) => (
                <>
                    <div>{v.province}{v.city}{v.county}{v.detail}</div>
                    <div>{v.name}</div>
                    <div>{v.phone}</div>
                </>
            )
        },
        {
            title: '快递号',
            dataIndex: 'logistics_no',
            render: (v,r) => {
                let l = <div>单号：{v}</div>
                try {
                    r.logistics_info = JSON.parse(r.logistics_info)
                    return <div>
                        <div>单号：{v}</div>
                        <div style={{color: "#94969B"}}>{r.logistics_info.Traces[r.logistics_info.Traces.length - 1].AcceptStation}</div>
                    </div>
                } catch (error) {
                    
                }
                return l
            }
        },
        {
            title: '付款时间',
            dataIndex: 'paytime',
            render: (v,r) => {
                if(!r.callback_data){
                    return
                }
                try {
                    let data = JSON.parse(r.callback_data)
                    return moment(data.success_time).format("YYYY-MM-DD HH:mm:ss")
                } catch (error) {
                    console.log(error)
                }
            }
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            render: (v) => (
                <div>
                    {
                        v === 1 && <span>待付款</span>
                    }
                    {
                        v === 2 && <span>待发货</span>
                    }
                    {
                        v === 3 && <span>待收货</span>
                    }
                    {
                        v === 4 && <span>已完成</span>
                    }
                </div>
            )
        },
        {
            title: '评价',
            dataIndex: 'evaluate',
            render: (v,r) => (
                <div>
                    {
                        v === 0 ? '未评价':
                        <div>
                            <Rate disabled value={r.order_evaluate.star_num} />
                            <div>{r.order_evaluate.content}</div>
                            <div>
                                {
                                    r.order_evaluate.media.map((i,idx) => (
                                        <div key={idx}>
                                            <a href={i.previewPath}>媒体文件{idx+1}</a>
                                            <br />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </div>
            )
        },
        
        {
            title: '操作',
            dataIndex: 'action',
            render: (v,r) => (
                <Space>
                    {
                        r.status === 1 && <Button loading={loading} type="link" onClick={async () => {
                            setLoading(true)
                            try {
                                await http.post(apis.ORDER_BATCH_TIP,[{
                                    uid: r.uid,
                                    title: "你的订单未付款",
                                    msg_type: "wait_pay",
                                    order_no: r.order_no
                                }])
                                message.success("成功")
                            } catch (error) {
                                
                            }
                            setLoading(false)
                        }}>提醒</Button>
                    }
                    {
                        r.order_evaluate.publish === 0 && r.status === 4 && <Button loading={loading} type="link" onClick={async () => {
                            Modal.confirm({
                                content: "确定发布吗?",
                                onOk: async () => {
                                    setLoading(true)
                                    try {
                                        await doPublish(r.order_no)
                                        await loadData(search)
                                        message.success("操作成功")
                                    } catch (error) {
                                        
                                    }
                                    setLoading(false)
                                }
                            })
                        }}>发布到广场</Button>
                    }
                    {
                        r.status === 2 && <>
                            <Button loading={loading} danger type="link" disabled={r.refund === 1} onClick={async () => {
                                setLoading(true)
                                try {
                                    let data = JSON.parse(r.callback_data)
                                    await http.post(`${apis.ORDER_REFUND}?order_no=${r.order_no}&appid=${data.appid}`)
                                    await loadData(search)
                                    message.success("成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }}>退款</Button>
                            <Button onClick={() => {
                                setCuidanOpen(true)
                                tempOrderCache.current = r
                            }} type="link" disabled={r.warn === 1}>催单回复</Button>
                            <Button type="link" loading={loading} disabled={r.production_status === 2} onClick={async () => {
                                setLoading(true)
                                try {
                                    await http.post(`${apis.ORDER_PRODUCTION}?order_no=${r.order_no}`)
                                    await loadData(search)
                                    message.success("成功")
                                } catch (error) {
                                    
                                }
                                setLoading(false)
                            }}>生产</Button>
                            <Button disabled={r.logistics_no !== ""} type="link" loading={loading} onClick={async () => {
                                tempOrderCache.current = r
                                setFahuoOpen(true)
                            }}>发货</Button>
                        </>
                    }
                </Space>
            )
        }  
    ];

    const delCol = (f) => {
        let idx = columns.findIndex(v => v.dataIndex === f)
        columns.splice(idx,1)
    }
    if(search.status === 1){
        delCol("logistics_no")
        delCol("evaluate")
        delCol("paytime")
    }
    if(search.status === 2){
        delCol("logistics_no")
        delCol("evaluate")  
    }
    if(search.status === 3){
        delCol("status")
        delCol("evaluate")  
        delCol("paytime")
        delCol("action")
    }
    
    const downFile = (url, fileName) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob"; // 通过文件下载url拿到对应的blob对象
        xhr.onload = () => {
          if (xhr.status === 200) {
            let link = document.createElement("a");
            let body = document.querySelector("body");
            link.href = window.URL.createObjectURL(xhr.response);
            link.download = fileName;
            link.click();
            body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }
        };
        xhr.send();
    };

    const sleep = (s) => {
        return new Promise(function(resolve, reject) { 
            setTimeout(()=>{
                resolve()
            },s)
        });
    }

    return (
        <div>
            <Modal title="发货" okButtonProps={{loading}} onOk={async () => {
                if(!fahuoValue){
                    return
                }
                setLoading(true)
                try {
                    await http.post(`${apis.ORDER_SHIPMENT}?order_no=${tempOrderCache.current.order_no}&logistics_no=${fahuoValue}`)
                } catch (error) {
                    
                }
                setLoading(false)
                setFahuoOpen(false)
                setFahuoValue('')
                loadData(search)
            }} onCancel={()=>{
                setFahuoOpen(false)
                tempOrderCache.current = {}
                setFahuoValue('')
            }} open={fahuoOpen}>
                <Input value={fahuoValue} onChange={e => {
                    setFahuoValue(e.target.value)
                }} placeholder="请输入物流单号" maxLength={100} />
            </Modal>
            <Modal title="催单回复" okButtonProps={{loading}} onOk={async () => {
                if(!cuidanValue){
                    return
                }
                setLoading(true)
                try {
                    await http.post(apis.ORDER_CUI,{
                        order_no: tempOrderCache.current.order_no,
                        uid: tempOrderCache.current.uid,
                        title: "催单回复",
                        msg_type: "cuidan",
                        content: cuidanValue
                    })
                } catch (error) {
                    
                }
                setLoading(false)
                setCuidanOpen(false)
                setCuidanValue('')
                loadData(search)
            }} onCancel={()=>{
                setCuidanOpen(false)
                tempOrderCache.current = {}
                setCuidanValue('')
            }} open={cuidanOpen}>
                <TextArea value={cuidanValue} onChange={e => {
                    setCuidanValue(e.target.value)
                }} placeholder="请输入内容" maxLength={100} />
            </Modal>
            <span style={{display: "none"}}>{update}</span>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Form layout="inline" onFinish={async (values)=>{
                    setLoading(true)
                    let start_date = ''
                    let end_date = ''
                    try {
                        start_date = values.dates[0].format("YYYY-MM-DD HH:mm:ss")
                        end_date = values.dates[1].format("YYYY-MM-DD HH:mm:ss")
                    } catch (error) {
                        
                    }
                    let cpSearch = cloneDeep(search)
                        cpSearch.page = 1
                        cpSearch.start_date = start_date
                        cpSearch.end_date = end_date
                        setSearch(cpSearch)
                        await loadData(cpSearch)
                    setLoading(false)
                }}>
                    <Form.Item name={"dates"}>
                        <RangePicker />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset">重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
                <Space>
                    {
                        search.status === 1 && <Button type="primary" loading={loading} disabled={selectedRowKeys.length === 0} onClick={async () => {
                            let post = []
                            for(let i=0;i<selectedRowKeys.length;i++){
                                let order_no = selectedRowKeys[i]
                                let item = (response.list || []).find(m => m.order_no === order_no)
                                post.push({
                                    uid: item.uid,
                                    title: "你的订单未付款",
                                    msg_type: "wait_pay",
                                    order_no: item.order_no
                                })
                            }
                            setLoading(true)
                            try {
                                await http.post(apis.ORDER_BATCH_TIP,post)
                                message.success("成功")
                                setSelectedRowKeys([])
                            } catch (error) {
                                
                            }
                            setLoading(false)
                        }}>批量提醒</Button>
                    }
                    <div>
                        <Space>
                            <span>0元购</span>
                            <Switch onChange={v => {
                                setResponse(pre => {
                                    const cpPre = cloneDeep(pre)
                                    cpPre.is_order_free = v
                                    return cpPre
                                })
                                http.post(`${apis.ORDER_FREE}?open=${v}`).catch(()=>{})
                            }} checked={response.is_order_free} />
                        </Space>
                    </div>
                    <Button type="primary" onClick={async () => {
                        for(let i=0;i<selectedRowKeys.length;i++){
                            let order_no = selectedRowKeys[i]
                            let item = (response.list || []).find(m => m.order_no === order_no)
                            item.material = JSON.parse(item.material)
                            console.log(item.material.imgs)
                            for(let i=0;i<item?.material?.imgs?.length;i++){
                                let a = item.material.imgs[i]
                                let link = process.env.REACT_APP_OSS_URL + "/" + a
                                downFile(link,`订单-${order_no}-${i}`)
                                await sleep(1000)
                            }
                            await sleep(1000)
                            downFile(process.env.REACT_APP_OSS_URL + "/" +item.cover,`订单(效果图)-${order_no}`)
                        }
                        // 
                    }} disabled={selectedRowKeys.length === 0}>导出</Button>
                </Space>
            </div>
            <div style={{height: 12}}></div>
            <Tabs defaultActiveKey={2} onChange={async v => {
                let cpSearch = cloneDeep(search)
                cpSearch.status = v
                setSearch(cpSearch)
                await loadData(cpSearch)
            }} items={[
                {
                    key: 1,
                    label: '待付款'
                },
                {
                    key: 2,
                    label: '已付款'
                },
                {
                    key: 3,
                    label: '已发货'
                },
                {
                    key: 4,
                    label: '已完成'
                }
            ]} />
            <Table 
                scroll={{
                    y: `calc(100vh - 410px - ${document.getElementsByClassName("ant-table-thead")[0]?.clientHeight}px)`
                }}
                rowSelection={{
                    selectedRowKeys,
                    onChange:(v)=>{
                        setSelectedRowKeys(v)
                    }
                }} 
                rowKey={`order_no`} 
                columns={columns} 
                loading={loading} 
                dataSource={response.list || []} 
                pagination={{
                    total: response.total,
                    pageSize: response.page_size,
                    onChange: async (page) => {
                        let cpSearch = cloneDeep(search)
                        cpSearch.page = page
                        setSearch(cpSearch)
                        setLoading(true)
                        await loadData(cpSearch)
                        setLoading(false)
                        setSelectedRowKeys([])
                    },
                    current: response?.page,
                }} />
        </div>
    )
}

export default Index