import React, { useEffect, useState } from 'react';
import { Layout, Menu, theme, Dropdown, Space } from 'antd';
import menuConfig from '../../router/config'
import Title from '../../static/img/3.png'
import { useNavigate } from "react-router-dom";
import * as utils from '../../utils'
import {MenuFoldOutlined,MenuUnfoldOutlined,SmileOutlined,DownOutlined,HomeOutlined} from '@ant-design/icons';
import * as apis from '../../lib/api'
import http from '../../lib/http'
import { Avatar } from 'antd';

const { Header, Content, Footer, Sider } = Layout;
const breadcrumbData = utils.routerHandle(menuConfig)


export const Context = React.createContext()

const Index = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys,setOpenKeys] = useState([])
  const [info, setInfo] = useState({})
  const [menuItemData, setMenuItemData] = useState(utils.menuItemHandle(menuConfig))
  const { token: { colorBgContainer } } = theme.useToken();
  const navigate = useNavigate()

  useEffect(()=>{
    setOpenKeys(openMenu())
    reloadInfo()
    // eslint-disable-next-line
  },[])

  const reloadInfo = () => {
    http.get(apis.ADMIN_INFO).then(r => {
      setInfo(r)
      let cpData = []
      for (let i=0;i<menuItemData?.length;i++){
        let item = menuItemData[i]
        if(permission(item.roleids,r.role_id)){
          cpData.push(item)
        }
      }
      console.log(cpData)
      setMenuItemData(cpData)
    })
  }

  const permission = (roleIds,rid) => {
    if(!roleIds || (roleIds && roleIds.length === 0)){
        return true //没配置就是有权限
    }
    return !!roleIds.find(i => i === rid)
  }

  const activityMenu = () => {
    let currPath = window.location.pathname
    let superMenu = utils.getSuperMenuKey(menuConfig,currPath)
    if(superMenu){
      return [currPath,superMenu.path]
    }else{
      return [currPath]
    }
  }

  const openMenu = () => {
    let currPath = window.location.pathname
    let superMenu = utils.getSuperMenuKey(menuConfig,currPath)
    return [superMenu?.path]
  }

  const getBreadcrumb = () => {
    let f = breadcrumbData?.find(v => v.path === window.location.pathname)
    if(f && f.breadcrumb){
      return f.breadcrumb
    }
    return <></>
  }

  return (
    <Context.Provider value={{
        reloadInfo
    }}> 
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider theme="light" trigger={null} collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value)
          setOpenKeys(openMenu())
        }}>
          <div
            style={{
              height: 32,
              margin: 16,
              background: 'rgba(255, 255, 255, 0.2)',
            }}
          >
            <img style={{width: "100%"}} src={Title} alt='' />
          </div>
          <Menu onOpenChange={(v)=>{
            setOpenKeys(v)
          }} openKeys={openKeys} selectedKeys={activityMenu()} onClick={(v)=>{
            navigate(v.key);
          }} mode="inline" items={menuItemData} />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              paddingLeft: 24,
              paddingRight: 24
            }}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <div>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: 'trigger',
                  onClick: () => {
                    setCollapsed(!collapsed)
                    if(collapsed){
                      setTimeout(()=>{setOpenKeys(openMenu())},100)
                    }
                    
                  },
                })}
              </div>
              <div style={{display: "flex", alignItems: "center"}}>
                <Avatar src={info.avatar ? process.env.REACT_APP_OSS_URL + "/" +info.avatar:""} style={{
                  marginRight: 12
                }} size={32}>头像</Avatar>
                <Dropdown
                  menu={{
                    items:[{
                      key: '1',
                      label: (
                        <span onClick={()=>{
                          
                          navigate("/me")
                        }}>
                          个人中心
                        </span>
                      ),
                      icon: <HomeOutlined />
                    },
                    {
                      key: '2',
                      label: (
                        <span onClick={()=>{
                          localStorage.removeItem("token")
                          navigate("/login")
                        }}>
                          注销
                        </span>
                      ),
                      icon: <SmileOutlined />
                    }]
                  }}
                >
                  <span style={{color: "#1677ff", cursor: "pointer"}} onClick={(e) => e.preventDefault()}>
                    <Space>
                      {info.name}
                      <DownOutlined />
                    </Space>
                  </span>
                </Dropdown>
                {/* <span onClick={() => navigate("/me")} style={{cursor: "pointer"}}>{info.name}</span> */}
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: '0 16px',
            }}
          >
            {
              getBreadcrumb()
            }
            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
              }}
            >
              {props.children}
            </div>
          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            ©2023 Created by Leto
          </Footer>
        </Layout>
      </Layout>
    </Context.Provider>
  );
};
export default Index;