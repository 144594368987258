import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from '../components/layout'
import rsConfig from './config'
import * as utils from '../utils'
import http from '../lib/http'
import * as apis from '../lib/api'
import React, { useEffect, useState } from "react";


const routerData = utils.routerHandle(rsConfig)


const R = () => {
    const [info, setInfo] = useState({})

    useEffect(()=>{
        if(window.location.pathname === "/login"){
            return
        }
        http.get(apis.ADMIN_INFO).then(r => {
            setInfo(r)
        })
    },[])

    const permission = (roleIds) => {
        if(!roleIds || (roleIds && roleIds.length === 0)){
            return true //没配置就是有权限
        }
        return !!roleIds?.find(i => i === info.role_id)
    }

    return (
        <BrowserRouter>
            <Routes>
                {
                    routerData?.map((v,k) => (
                        (!v.layout && permission(v.roleIds)) && <Route key={k} path={v.path} element={v.component} />
                    ))
                }
                <Route path="/*" element={
                    <Layout>
                        <Routes>
                            {
                                routerData?.map((v,k) => (
                                    (v.layout && permission(v.roleIds)) && <Route key={k} path={v.path} element={v.component} />
                                ))
                            }
                        </Routes>
                    </Layout> 
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default R
